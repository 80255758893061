import api from './api';

export const $sp_rebateOrder = (params) => api.get('/j/rebate/rebateOrder/listPlatformOrders', params);
// /j/rebate/rebateOrder/getPlatformOrderTotal;
export const $sp_getPlatformOrderTotal = (params) => api.get('/j/rebate/rebateOrder/getPlatformOrderTotal', params);
// 导出文件 /j/rebate/rebateOrder/PlatformOrdersExcelOutPut
export const $sp_PlatformOrdersExcelOutPut = (params) => api.get('/j/rebate/rebateOrder/PlatformOrdersExcelOutPut', params);
// 订单详情 /j/rebate/rebateOrder/getPlatformOrderDetail
export const $sp_getPlatformOrderDetail = (params) => api.get('/j/rebate/rebateOrder/getPlatformOrderDetail', params);
// 提现列表  http://192.168.2.150:9550 /j/rebate/userAccountCashOutOrders
export const $sg_userAccountCashOutOrders = (params) => api.get('/j/rebate/userAccountCashOutOrders', params);
// 返利商户列表 /j/rebate/page/getShopPage http://192.168.2.153:9550/
export const $sg_shopBuyRebateList = (params) => api.postNoMessage('/j/rebate/page/getShopPage', params);
// 返利用户列表 /j/rebate/page/getUserPage
export const $sg_userBuyRebateList = (params) => api.postNoMessage('/j/rebate/page/getUserPage', params);
// 团队管理 /j/rebate/rebateUser/total
export const $sg_groupManagement = (params) => api.get('/j/rebate/rebateUser/total', params);
// 团队管理详情 /j/rebate/rebateUser/totalDetail
export const $sg_totalDetail = (params) => api.get('/j/rebate/rebateUser/totalDetail', params);
// 提现打钱 /j/rebate/userAccountCashOutOrders/auditAction
export const $sg_auditAction = (params) => api.post('/j/rebate/userAccountCashOutOrders/auditAction', params);
// 普通代理列表 /j/rebate/agent/v2/ordinary/page
export const $sg_ordinaryPage = (params) => api.postNoMessage('/j/rebate/agent/v2/ordinary/page', params);
// 返利卡总数据 /j/rebate/marketingCardOrder/statistic
export const $sg_marketingCardOrder = (params) => api.postNoMessage('/j/rebate/marketingCardOrder/statistic', params);
// 返利卡列表 /j/rebate/marketingCardOrder/page
export const $sg_CardOrderPage = (params) => api.postNoMessage('/j/rebate/marketingCardOrder/page', params);
//  优惠券列表 status /j/rebate/couponOrder/queryList
export const $sg_couponOrders = (params) => api.get('/j/rebate/couponOrder/queryList', params);
// 优惠券统计数据 /j/rebate/couponOrder/queryTotal
export const $sg_couponOrderTotal = (params) => api.get('/j/rebate/couponOrder/queryTotal', params);
// 优惠券详情 /j/rebate/couponOrder/queryOrderDetail
export const $sg_couponQueryOrderDetail = (params) => api.get('/j/rebate/couponOrder/queryOrderDetail', params);
// 三方优惠券列表
export const $sg_getRebateCouponList = (params) => api.get('/j/rebate/platRebateCoupon/getRebateCouponList', params);
// 三方优惠券品牌列表
export const $sg_getPlatCouponBrandList = (params) => api.get('/j/rebate/platRebateCoupon/getPlatCouponBrandList', params);
// 三方优惠券更改上下架状态
export const $sg_exchangeCouponState = (params) => api.get('/j/rebate/platRebateCoupon/exchangeCouponState', params);
// 新增优惠卷-初始化过程
export const $sg_initCoupon = (params) => api.get('/j/rebate/platRebateCoupon/initCoupon', params);
// 优惠券详情
export const $sg_platCouponDetail = (params) => api.get('/j/rebate/platRebateCoupon/platCouponDetail', params);
// 新增优惠卷-编辑主体信息
export const $sp_editCouponInfo = (params) => api.post('/j/rebate/platRebateCoupon/editCouponInfo', params);
// 上传和保存单张图片到oss和数据库
export const $sp_addCoupon = (params) => api.postNoMessage('/j/rebate/platCouponCode/addCoupon', params);
// 批量删除oss和数据库本次上传图片
export const $sg_delCoupon = (params) => api.get('/j/rebate/platCouponCode/delCouponCode', params);
// 新增优惠卷-取消-只删除卷码
export const $sg_delCouponInfo = (params) => api.get('/j/rebate/platRebateCoupon/delCouponInfo', params);
// 新增优惠卷-取消-只删除本页面上传的卷码
export const $sg_delCouponInfoMain = (params) => api.get('/j/rebate/platRebateCoupon/delCouponInfoMain', params);
// 品牌列表 /j/rebate/couponOrder/queryBrand 
export const $sg_queryBrand = (params) => api.get('/j/rebate/couponOrder/queryBrand ', params);
