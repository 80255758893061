<template>
  <div class="buy-rebate-order-page">
    <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
      <div class="timeAndBtn flexbox flex-lr flex-align-center mr20">
        <el-input v-model="form.orderId" placeholder="请输入订单编号" style="width:200px;" clearable></el-input>
        <el-input v-model="form.phone" type="number" placeholder="请输入用户手机号" style="width:160px; margin-right:25px;" clearable class="ml20"
          @input="inputPhone"></el-input>

        <el-select v-model="form.couponBrand" placeholder="请选择优惠券品牌" clearable class="ml20">
          <el-option v-for="item in couponList" :key="item.itemId" :label="item.itemValue" :value="item.itemId">
          </el-option>
        </el-select>
        <el-date-picker v-model="time" class="ml20" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <el-button type="primary" class="sure" @click="search">查询</el-button>
      <el-button type="info" class="sure" @click="clear">清空</el-button>

      <PrevReclick>
        <el-button type="primary" class="sure" @click="exportExcle">导出</el-button>
      </PrevReclick>
    </div>
    <div class="orderDetail">
      <span>订单总笔数：{{orderCount}}</span>
      <span>用户支付总金额：{{totalMoney}}</span>
      <span>分润总金额：{{totalRebate}}</span>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="orderId" label="订单编号" width="180"></el-table-column>
        <el-table-column prop="couponName" label="优惠券名称"></el-table-column>
        <el-table-column prop="couponBrand" label="品牌"></el-table-column>
        <el-table-column prop="userPayAmount" label="用户支付金额"></el-table-column>
        <el-table-column prop="createTime" label="支付时间">

        </el-table-column>
        <el-table-column prop="expireTime" label="质保期至" width="160">

        </el-table-column>
        <el-table-column prop="profitAmount" label="分润总金额"></el-table-column>
        <el-table-column prop="phone" label="用户手机号" align="center">
        </el-table-column>
        <el-table-column prop="status" label="订单状态">
          <template slot-scope='scope'>
            <span>{{statusList[scope.row.status]}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="goodCount" label="订单详情" align="center" width="120">
          <template slot-scope="scope">
            <div style="text-align:center;color:#ef3f46;cursor: pointer;" @click="openDetail(scope.row)">详情</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
    <el-dialog class="advert-detail-dialog" :visible="orderVisible" width="1000px" @close="orderVisible=false" title="订单详情">
      <aside class="order-detail-content">
        <section class="shop-data">
          <p class="shop-data-p">
            <img :src="orderDetail.couponHead" alt="">
          </p>
          <ul>
            <li v-for="(item,index) in orderLableList" :key="index" class="shop-data-li">
              {{item.name}}：{{item.remark?item.remark:''}}{{orderDetailResolve(item)}}</li>
          </ul>
          <ul>
            <li v-for="(item,index) in orderLableList1" :key="index" class="shop-data-li">
              {{item.name}}：{{item.remark?item.remark:''}}{{orderDetailResolve(item)}}</li>
          </ul>
        </section>
      </aside>
      <hr />
      <p style="text-align:center;font-size: 18px;color: #303133;line-height:40px">分润明细</p>
      <p style="font-size: 14px;color: #444444;line-height:40px">分润总金额: &yen;{{orderDetail['profitAmount']}}</p>
      <el-table :data="shareProfitDtos" style="width: 100%;" border>
        <el-table-column prop="name" label="名称" width="180"></el-table-column>
        <el-table-column prop="type" label="身份类型"></el-table-column>
        <el-table-column prop="tel" label="账号"></el-table-column>
        <el-table-column prop="profitScale" label="分润比例(%)">
          <template slot-scope="scope">
            <span>{{((+scope.row.profitScale))+'%'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="profitAmount" label="分润金额(元)"></el-table-column>
      </el-table>
      <hr />
      <p style="text-align:center;font-size: 18px;color: #303133;line-height:40px">卡劵信息</p>
      <p style="font-size: 14px;color: #444444;line-height:40px">数量:{{couponInfoVos.length}}</p>
      <el-table :data="couponInfoVos" style="width: 100%; margin-bottom: 45px;" border>
        <el-table-column prop="keys" label="序号" align="center" width="400px"></el-table-column>
        <el-table-column prop="code" label="券码编号" align="center"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import {
  $sg_couponOrders, $sg_couponOrderTotal, $sg_couponQueryOrderDetail, $sg_queryBrand,
} from '@/api/buyRebate';
import { exportFileSync } from '@base/utils';

const orderState = ['已完成', '退款中', '退款中', '退款失败', '已退款', '退款失败'];
const orderLableList = [{ name: '品牌', type: 'couponBrand' }, { name: '订单编号', type: 'orderId' },
  { name: '支付时间', type: 'createTime' }, { name: '用户电话', type: 'phone' }, { name: '订单状态', type: 'status' },
];
const orderLableList1 = [{ name: '优惠券原价格', type: 'originalPrice', remark: '￥' }, { name: '优惠券售价', type: 'sellingPrice', remark: '￥' },
  { name: '用户购买数量', type: 'couponNum' },
  { name: '订单实付金额', type: 'userPayAmount', remark: '￥' }, { name: '订单收益金额', type: 'profitAmount', remark: '￥' }];

const statusList = ['已完成', '已申请退款', '待退款', '拒绝退款', '已退款', '退款异常'];
export default {
  data() {
    return {
      statusList,
      brandlist: [
        { value: 1, label: '待审核' },
        { value: 2, label: '成功' },
        { value: 3, label: '驳回' },
      ],
      form: {
        orderId: '',
        couponBrand: '',
        phone: '',

      },
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      orderAmount: 0,
      orderNum: 0,
      orderVisible: false,
      orderLableList,
      orderLableList1,
      orderDetail: {},
      orderState,
      shareProfitDtos: [],
      totalMoney: '',
      orderCount: '',
      totalRebate: '',
      couponInfoVos: [],
      couponList: [],
    };
  },
  created() {
    this.getTableData();
    this.queryBrand();
  },
  mounted() {

  },
  methods: {
    queryBrand() {
      $sg_queryBrand().then((res) => {
        this.couponList = res;
      });
    },
    orderDetailResolve(item) {
      if (this.orderDetail.status === 4) {
        if (item.type === 'status') {
          return this.orderState[this.orderDetail.status];
        } else if (item.type === 'profitAmount') {
          return 0;
        } else {
          return this.orderDetail[item.type];
        }
      } else if (item.type === 'status') {
        return this.orderState[this.orderDetail.status];
      } else {
        return this.orderDetail[item.type];
      }
    },
    openDetail(item) {
      $sg_couponQueryOrderDetail({ orderId: item.orderId, couponId: item.couponId }).then((res) => {
        this.orderVisible = true;
        this.orderDetail = res || {};
        if (this.orderDetail.payTime) {
          this.orderDetail.payTime = this.$formatDate(this.orderDetail.payTime, 'yyyy-MM-dd HH:mm:ss');
        }
        this.shareProfitDtos = res.shareProfitDtos;
        this.couponInfoVos = res.couponInfoVos || [];
        // eslint-disable-next-line no-shadow
        this.couponInfoVos.forEach((item, index) => {
          item.keys = index + 1;
        });
      });
    },
    // 导出列表
    exportExcle() {
      let startTime = null; let endTime = null;
      if (this.time) {
        startTime = (this.time[0]).getTime() || null; endTime = (this.time[1]).getTime() || null;
      }
      // $sp_exportExcle({ query: this.form.query, startTime, endTime }).then(() => {

      // });
      exportFileSync(
        '/j/rebate/couponOrder/exportOrders',
        {
          current: this.currentPage,
          pageSize: 15,
          ...this.form,
          startTime,
          endTime,
        },
        'get',
        '订单.xlsx',
        this.onDownloadProgress,
      ).then(() => {
        // this.handleDownloadQrCodeLeftBtn();
      });
    },
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 校验手机号-最多输入11位
    inputPhone(value) {
      if (value.length > 11) {
        this.form.shopAccount = value.slice(0, 11);
      }
    },
    // 清空
    clear() {
      this.form = {
        orderId: '',
        couponBrand: '',
        phone: '',

      };
      this.currentPage = 1;
      this.time = '';
      this.getTableData();
    },
    // 请求列表数据
    getTableData() {
      const params = {
        current: this.currentPage,
        pageSize: 15,
        ...this.form,
      };
      if (this.time) {
        params.startTime = ((this.time[0]).getTime());
        params.endTime = ((this.time[1]).getTime());
      } else {
        params.startTime = '';
        params.endTime = '';
      }
      // 请求订单数据
      $sg_couponOrders(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
      // 请求订单汇总
      $sg_couponOrderTotal(params).then((res) => {
        this.orderCount = res.orderCount || 0;
        this.totalMoney = res.totalMoney || 0;
        this.totalRebate = res.totalRebate || 0;
      });
    },


    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" >
hr {
  margin-top: 45px;
}
.buy-rebate-order-page {
  width: 100%;
  min-width: 1400px;
  // height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .longwidth {
    width: 300px !important;
  }
  .orderDetail {
    width: 100%;
    margin-top: 40px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f0f0f0;
    font-size: 14px;
    color: #555;
    span:first-child {
      margin-right: 80px;
    }
    span:nth-child(2) {
      margin-right: 80px;
    }
  }
  .tableBox {
    width: 100%;
    margin-top: 40px;
  }
  .el-table {
    // max-height: 400px;
    // overflow-y:auto;
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
  .el-dialog__header {
    text-align: center;
  }
  .el-dialog__body {
    color: #444444;
    padding: 15px 20px;
    max-height: 800px;
    overflow-y: auto;
  }
  .order-detail-content {
    // display: flex;
    // flex-flow: wrap;
    // justify-content: space-between;
    padding: 0 20px;
    .shop-data {
      display: flex;
      flex-flow: wrap;
      justify-content: space-around;
    }
    .shop-data-li {
      width: 220px;
    }
    .shop-data-p {
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 150px;
      }
    }
    .share-profits {
      display: flex;
      flex-flow: wrap;
    }
    li {
      line-height: 30px;
      width: 190px;
    }
  }
}
</style>

